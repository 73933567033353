.section-2,
.section-2 * {
  box-sizing: border-box;
}
.section03-wrap {
  width: 100%;
  background: #f3f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-2 {
  width: 1200px;
  padding: 160px 0;
  display: flex;
  flex-direction: row;
  gap: 96px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.strength {
  color: #0060d1;
  text-align: center;
  /* Web/Title/S-24-Bold */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
}
.div {
  color: var(--Alias-Color-Text-Dark-Text-Primary, #171e26);

  /* Spoqa/Title/40_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 58px */
  letter-spacing: -2px;
}
.frame-92 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-90 {
  border-style: solid;
  border-color: var(--alias-color-text-dark-text-primary, #171e26);
  border-width: 1px 0px 0px 0px;
  padding: 40px 0px 40px 16px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.paragraph {
  color: var(--Alias-Color-Text-Dark-Text-Disabled, rgba(23, 30, 38, 0.4));
  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}
.frame-91 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.paragraph2 {
  color: var(--Alias-Color-Text-Dark-Text-Primary, #171e26);

  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}
.frame-93 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.paragraph3 {
  color: var(--Alias-Color-Text-Dark-Text-Secondary, rgba(23, 30, 38, 0.78));

  /* Spoqa/Body/18_Medium */
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.frame-912 {
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px 0px 0px 0px;
  padding: 40px 0px 40px 16px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-94 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-922 {
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px 0px 0px 0px;
  padding: 40px 0px 40px 16px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
