.main-banner,
.main-banner * {
  box-sizing: border-box;
}
.main-banner {
  height: 711px;
  position: relative;
  overflow: hidden;
  background-image: url("/public/asset/images/Main/main_banner.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-banner .text-area {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1200px;
}
.main-banner .div {
  color: #ffffff;
  text-align: left;
  font-family: "Spoqa Han Sans Neo";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 69.6px */
  letter-spacing: -2.4px;
}
.main-banner .text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.main-banner .problem-solving {
  color: var(
    --alias-color-text-white-text-secondary,
    rgba(255, 255, 255, 0.78)
  );
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
}
.main-banner .ai-big-data-blockchain-hecto-labs {
  color: #ffffff;
  text-align: left;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.9px;
}
.main-banner .group-43235 {
  position: absolute;
  inset: 0;
}
.main-banner .key-2-1 {
  width: 281px;
  height: 192px;
  position: absolute;
  left: 1004px;
  top: 396px;
  object-fit: cover;
}
.main-banner .web-1 {
  width: 209px;
  height: 179px;
  position: absolute;
  left: 1470px;
  top: 354px;
  object-fit: cover;
}
.main-banner .frame-2609928 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 264px;
  position: absolute;
  left: 1217px;
  top: 202px;
}
.main-banner .code-1 {
  align-self: stretch;
  flex-shrink: 0;
  height: 304px;
  position: relative;
  object-fit: cover;
}
.main-banner .ellipse-492 {
  background: #001124;
  border-radius: 50%;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  position: relative;
  filter: blur(28px);
}
.main-banner .bole-2-1 {
  width: 156px;
  height: 161px;
  position: absolute;
  left: 699px;
  top: 97px;
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  filter: blur(2px);
  object-fit: cover;
}
.main-banner .bole {
  width: 44px;
  height: 40px;
  position: absolute;
  left: 1679px;
  top: 226px;
  filter: blur(2px);
  object-fit: cover;
}
.main-banner .bole-4-1 {
  width: 327px;
  height: 322px;
  position: absolute;
  left: 107px;
  top: 579px;
  filter: blur(2px);
  object-fit: cover;
}
.main-banner .bole-4-2 {
  width: 61px;
  height: 60px;
  position: absolute;
  left: 1739px;
  top: 396px;
  filter: blur(2px);
  object-fit: cover;
}
