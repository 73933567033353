.topnav-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 90px;
}
.topnav-inner {
  width: 1200px;
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
  height: 100%;
}
.topnav-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.topnav-right-inner {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 112px;
}
.tab-menu-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 40px;
}
.tab-menu {
  padding: 8px 16px;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 165%;
  letter-spacing: -0.16px;
  text-decoration: none;
  color: white;
  border-bottom: 2px solid transparent;
}
.tab-menu.selected {
  border-bottom: 2px solid white;
}
.contact-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: #fff;
  border: none;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 165%; /* 26.4px */
  letter-spacing: -0.16px;
  color: #0060d1;
  cursor: pointer;
}
