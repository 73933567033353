section-1-inner.section-1-inner,
.section-1-inner * {
  box-sizing: border-box;
}
.section-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-1-inner {
  background: var(--neutral-0, #ffffff);
  padding: 80px 0 160px 0;
  display: flex;
  flex-direction: column;
  gap: 128px;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 1200px;
}
.section-1-inner .title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.section-1-inner .reasons {
  color: #0060d1;
  text-align: center;
  font-family: var(
    --web-title-s-24-bold-font-family,
    "Pretendard-Bold",
    sans-serif
  );
  font-size: var(--web-title-s-24-bold-font-size, 24px);
  line-height: var(--web-title-s-24-bold-line-height, 145%);
  font-weight: var(--web-title-s-24-bold-font-weight, 700);
  position: relative;
}
.section-1-inner .div {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: center;
  font-family: var(
    --spoqa-title-40bold-font-family,
    "SpoqaHanSansNeo-Bold",
    sans-serif
  );
  font-size: var(--spoqa-title-40bold-font-size, 40px);
  line-height: var(--spoqa-title-40bold-line-height, 145%);
  letter-spacing: var(--spoqa-title-40bold-letter-spacing, -0.05em);
  font-weight: var(--spoqa-title-40bold-font-weight, 700);
  position: relative;
}
.section-1-inner .contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.section-1-inner ._1 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.section-1-inner .text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.section-1-inner .headline {
  color: var(--Alias-Color-Text-Dark-Text-Primary, #171e26);
  text-align: center;
  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}
.section-1-inner .div2 {
  color: var(--Alias-Color-Text-Dark-Text-Secondary, rgba(23, 30, 38, 0.78));
  text-align: center;
  /* Spoqa/Body/18_Medium */
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.section-1-inner .line-6 {
  width: 1.5px;
  height: 160px;
  background: var(--Alias-Color-Line-Border, rgba(23, 30, 38, 0.1));
}
