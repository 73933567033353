.section-4,
.section-4 * {
  box-sizing: border-box;
}

.section04-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-4 {
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  gap: 128px;
  align-items: center;
  justify-content: center;
  width: 1200px;
}
.section04-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.performance {
  color: #0060d1;
  text-align: center;
  font-family: var(
    --web-title-s-24-bold-font-family,
    "Pretendard-Bold",
    sans-serif
  );
  font-size: var(--web-title-s-24-bold-font-size, 24px);
  line-height: var(--web-title-s-24-bold-line-height, 145%);
  font-weight: var(--web-title-s-24-bold-font-weight, 700);
  position: relative;
}
.div {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: center;
  font-family: var(
    --spoqa-title-40bold-font-family,
    "SpoqaHanSansNeo-Bold",
    sans-serif
  );
  font-size: var(--spoqa-title-40bold-font-size, 40px);
  line-height: var(--spoqa-title-40bold-line-height, 145%);
  letter-spacing: var(--spoqa-title-40bold-letter-spacing, -0.05em);
  font-weight: var(--spoqa-title-40bold-font-weight, 700);
  position: relative;
}
.contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.s4-card {
  background: #ffffff;
  border-radius: 24px;
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  padding: 40px 32px 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  width: 384px;
  position: relative;
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
  overflow: hidden;
  gap: 42px;
}
.frame-80 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-2609921 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sub {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}
.s4-paragraph {
  color: #0060d1;

  /* Spoqa/Body/18_Medium */
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.s4-paragraph2 {
  color: var(--Alias-Color-Text-Dark-Text-Primary, #171e26);

  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}

.group-43230 {
  position: absolute;
  inset: 0;
}
.ellipse-490 {
  width: 31.2px;
  height: 42px;
  position: absolute;
  left: 8.33px;
  top: 3.5px;
  overflow: visible;
}
.ellipse-491 {
  background: #ffffff;
  border-radius: 50%;
  width: 19.6px;
  height: 19.6px;
  position: absolute;
  left: 14.13px;
  top: 9.15px;
}
.div4 {
  color: #0060d1;
  text-align: center;
  font-family: "Pretendard-Black", sans-serif;
  font-size: 11px;
  line-height: 145%;
  font-weight: 900;
  position: absolute;
  left: 17.11px;
  top: 10.34px;
  width: 13.65px;
  height: 19.91px;
}
.s4-paragraph3 {
  color: var(--Alias-Color-Text-Dark-Text-Secondary, rgba(23, 30, 38, 0.78));

  /* Spoqa/Body/18_Medium */
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}

.group-43007 {
  height: auto;
  position: absolute;
  left: 0px;
  top: 4px;
  overflow: visible;
}

.sub2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 156px;
  position: relative;
}
.group-43232 {
  height: auto;
  position: absolute;
  left: 10.67px;
  top: 5.5px;
  overflow: visible;
}
.frame-2609953 {
  flex-shrink: 0;
  width: 103px;
  height: 32px;
  position: relative;
  overflow: hidden;
}
.group-43234 {
  height: auto;
  position: absolute;
  left: 0px;
  top: 6px;
  overflow: visible;
}

.frame-81 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.frame-2609929 {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
}
.group-43231 {
  height: auto;
  position: absolute;
  left: 9px;
  top: 5.5px;
  overflow: visible;
}
