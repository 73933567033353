.ai-section-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 448px;
  background-color: #00316b;
  display: flex;
  justify-content: center;
}
.section-content-box {
  width: 100%;
}
.ai-section-wrap-bg-white {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.ai-section-wrap-bg-gradient {
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(180deg, #0060d1 0%, #00316b 100%);
  display: flex;
  justify-content: center;
}
.ai-section01-inner {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 112px;
}
.ai-section-inner-p112 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: center;
  padding: 112px 0;
}
.ai-section01-title {
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 58px */
  color: white;
}
.ai-section01-text {
  color: rgba(255, 255, 255, 0.78);
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.hidden-text {
  height: 1px;
  left: -9999px;
  overflow: hidden;
  position: absolute;
  top: -9999px;
  width: 1px;
}
.keywords-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.keyword {
  width: fit-content;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #fff;
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.7px;
  color: #fff;
  background: none;
  cursor: pointer;
}
.selectedKeyword {
  width: fit-content;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #fff;
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.7px;
  color: #0060d1;
  background: #fff;
  cursor: pointer;
}
.keywordgray {
  width: fit-content;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid rgba(23, 30, 38, 0.56);
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.7px;
  color: rgba(23, 30, 38, 0.56);
}
.keywordblue {
  width: fit-content;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #0060d1;
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.7px;
  color: #0060d1;
}
.keyword-design {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}
.keyword-card {
  width: 384px;
  height: 590px;
  padding: 40px 32px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.keyword-card-img-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.7px;
  color: #0060d1;
  padding: 24px 16px;
  border-radius: 24px;
  background: #f3f8ff;
}
.keyword-card-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.duration {
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.7px;
  color: #0060d1;
}
.keyword-title {
  color: #171e26;
  font-family: "Spoqa Han Sans Neo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 29px */
  letter-spacing: -1.22px;
}
.keyword-des {
  font-family: "Spoqa Han Sans Neo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 23.2px */
  letter-spacing: -0.8px;
  color: rgba(23, 30, 38, 0.78);
}
.keyword-pagination-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-btn-box {
  display: flex;
  gap: 24px;
}
.control-btn-box img {
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
}
.paging-number-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.page-number {
  width: 56px;
  height: 56px;
  border-radius: 100px;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page-number.current-page {
  background-color: #fff;
  color: #0060d1;
  cursor: pointer;
}
.solution-design {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.ai-solution-card {
  display: flex;
  flex-direction: column;
  gap: 72px;
  flex: 1;
}
.ai-solution-card img {
  width: 588px;
}
.ai-solution-text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 32px;
  border-radius: 24px;
  border: 1px solid rgba(23, 30, 38, 0.1);
  height: 217px;
}

.ai-solution-text1 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.ai-solution-text2 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: rgba(23, 30, 38, 0.78);
}
.ai-market-performance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.performance-content-box {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
