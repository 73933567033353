.team-section-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 448px;
  background-color: #00316b;
  display: flex;
  justify-content: center;
}
.team-section-wrap-bg-white {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.section-content-box {
  width: 100%;
}
.team-section01-inner {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 112px;
}
.team-section-inner-p112 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: center;
  padding: 112px 0;
}
.team-section01-title {
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 58px */
  color: white;
}
.team-section01-text {
  color: rgba(255, 255, 255, 0.78);
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.gray-text {
  font-family: "Spoqa Han Sans Neo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: -0.8px;
  color: rgba(23, 30, 38, 0.4);
}
.weare-design {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
}
.weare-card {
  width: 384px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  border: 1px solid rgba(23, 30, 38, 0.1);
  background: #fff;
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
}
.weare-card-text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.weare-card-text-top {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.profile-name {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.profile-education {
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  color: rgba(23, 30, 38, 0.4);
}
.weare-card-text-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.weare-card-list-item {
  font-family: "Spoqa Han Sans Neo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 23.2px */
  letter-spacing: -0.8px;
  color: rgba(23, 30, 38, 0.78);
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}
