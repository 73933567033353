.how-section-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 474px;
  background-color: #00316b;
  display: flex;
  justify-content: center;
}
.how-section-wrap-bg-white {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 112px 0;
}
.how-section-wrap-bg-lightblue {
  width: 100%;
  background-color: #f5f7f9;
  display: flex;
  justify-content: center;
  padding: 112px 0;
}
.how-section01-inner {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 112px;
}
.how-section01-title {
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 58px */
  color: white;
}
.how-section01-text {
  color: rgba(255, 255, 255, 0.78);
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
.how-section02-inner {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.section-title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
}
.section-title-text {
  width: 100%;
  color: #0060d1;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  text-align: left;
}
.section-description-text {
  width: 100%;
  color: #171e26;
  font-family: "Spoqa Han Sans Neo";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 46.4px */
  letter-spacing: -1.6px;
}
.section-content-box {
  width: 100%;
}
.solution-design {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
}
.solution-design-card-box {
  width: 584px;
  padding: 40px 32px;
  border-radius: 24px;
  border: 1px solid #0060d1;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  box-sizing: border-box;
}
.solution-design-card-texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.solution-design-card-text1 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.solution-design-card-text2 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: rgba(23, 30, 38, 0.78);
}
.solution-design-img-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.solution-design-process {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 40px;
}
.solution-design-process-card {
  box-sizing: border-box;
  width: 270px;
  padding: 40px 32px;
  border-radius: 24px;
  border: 1px solid var(--Alias-Color-Line-Border, rgba(23, 30, 38, 0.1));
  background: #fff;
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.s3-process-card-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.s3-process-number {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: rgba(23, 30, 38, 0.56);
}
.s3-process-tag {
  font-family: "Spoqa Han Sans Neo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 23.2px */
  letter-spacing: -0.8px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(23, 30, 38, 0.56);
  color: rgba(23, 30, 38, 0.56);
}
.s3-process-card-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
.s3-process-card-bottom-textWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.s3-process-card-bottom-title {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.s3-process-card-bottom-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.s3-process-card-bottom-li {
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: rgba(23, 30, 38, 0.78);
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
}
.s4-right-content {
  width: 555px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #171e26;
}
.s4-right-content-item {
  width: 100%;
  display: flex;
  gap: 32px;
  padding: 40px 0 40px 16px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.s4-right-content-item-textbox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.s4-right-content-item-text1 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.s4-right-content-item-text2 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: rgba(23, 30, 38, 0.78);
}
.how-s4-content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Hecto-Labs-AI-Solution {
  width: 100%;
  padding: 48px;
  background-color: #f3f8ff;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.solution-text-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ai-solution-text1 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.ai-solution-text2 {
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  color: rgba(23, 30, 38, 0.78);
}
.how-s4-img {
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
}
