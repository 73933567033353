.inquiry-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.inquiry-popup {
  background-color: #fff;
  width: 360px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}
.inquiry-popup-title-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.inquiry-popup-title {
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: -0.8px;
  color: #171e26;
  text-align: center;
  flex: 1;
}
.inquiry-popup-content-box {
  width: 100%;
  height: 720px;
  padding: 48px 16px 110px 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-y: auto;
}
.inquiry-popup-content-box::-webkit-scrollbar {
  width: 8px; /* 스크롤바의 너비를 8px로 설정 */
}
.inquiry-popup-content-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* 스크롤바의 색상 */
  border-radius: 10px; /* 스크롤바의 모서리를 둥글게 */
}
.inquiry-popup-content-title {
  width: 100%;
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
  color: #171e26;
}
.inquiry-input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.inquiry-input-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inquiry-input-error {
  font-family: "Spoqa Han Sans Neo";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 18.85px */
  letter-spacing: -0.65px;
  color: rgba(236, 79, 79, 1);
  padding-left: 8px;
  padding-top: 4px;
}
.inquiry-input-title {
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
  color: #171e26;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.inquiry-select {
  width: 100%;
  display: flex;
  gap: 20px;
}
.inquiry-select-option {
  display: flex;
  width: 96px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  background: #f7f8f9;
  flex: 1;
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
  color: rgba(23, 30, 38, 0.56);
  cursor: pointer;
}
.inquiry-select-option-selected {
  display: flex;
  width: 96px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  background: #0060d1;
  flex: 1;
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
  color: #fff;
  cursor: pointer;
}
.inquiry-input {
  width: 100%;
  padding: 13px 16px;
  border: 1px solid rgba(23, 30, 38, 0.4);
  border-radius: 5px;
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
  border: 1px solid rgba(23, 30, 38, 0.1);
}
.inquiry-input::placeholder {
  color: rgba(23, 30, 38, 0.4);
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
}
.inquiry-textarea {
  width: 100%;
  padding: 13px 16px;
  border: 1px solid rgba(23, 30, 38, 0.4);
  border-radius: 5px;
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
  border: 1px solid rgba(23, 30, 38, 0.1);
  height: 274px;
}
.inquiry-textarea::placeholder {
  color: rgba(23, 30, 38, 0.4);
  font-family: "Spoqa Han Sans Neo";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.75px;
}

.content-maxlength {
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: rgba(23, 30, 38, 0.4);
}
.submit-btn-container {
  width: 100%;
  background-color: #fff;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.submit-btn {
  border: none;
  width: 328px;
  height: 54px;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.18px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
