.update-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.update-inner {
  width: 1200px;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative; /* Added to ensure arrows position correctly */
}

.update-title {
  width: 100%;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 24px;
  color: #0060d1;
}

.update-slide-card {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(23, 30, 38, 0.1);
  background: #fff;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 180px;
}
.update-card-category {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #0060d1;
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #0060d1;
  width: fit-content;
  margin-bottom: 16px;
}
.update-card-text {
  width: 100%;
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-weight: 700;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
  margin-bottom: 16px;
  width: 341px;
}
.update-card-tag {
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(23, 30, 38, 0.78);
}
.slider-btn-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
}
.slider-btn-box img {
  cursor: pointer;
}

.slick-list {
  padding: 0 16px; /* Add horizontal padding to the slider container */
}

.slick-slide {
  padding: 0 8px; /* Add padding for individual slides */
}
