.footer,
.footer * {
  box-sizing: border-box;
}
.footer {
  width: 100%;
  background: var(--alias-color-text-dark-text-primary, #171e26);
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 1200px;
  padding: 60px 0 100px 0;
}
.footer-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.footer-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
}
.text-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}
.row-item-text {
  font-family: "Spoqa Han Sans Neo";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
  letter-spacing: -0.6px;
  color: rgba(255, 255, 255, 0.64);
}
.divider-line {
  width: 1px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.64);
}
