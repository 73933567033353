.section-5,
.section-5 * {
  box-sizing: border-box;
}
.section05-wrap {
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
.section-5 {
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  gap: 160px;
  align-items: center;
  justify-content: center;
  width: 1200px;
}
.s5-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 128px;
  align-items: flex-start;
  justify-content: center;
}
.s5-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.process {
  color: #0060d1;
  text-align: center;
  font-family: var(
    --web-title-s-24-bold-font-family,
    "Pretendard-Bold",
    sans-serif
  );
  font-size: var(--web-title-s-24-bold-font-size, 24px);
  line-height: var(--web-title-s-24-bold-line-height, 145%);
  font-weight: var(--web-title-s-24-bold-font-weight, 700);
}
.s5-title-text {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: center;
  font-family: var(
    --spoqa-title-40bold-font-family,
    "SpoqaHanSansNeo-Bold",
    sans-serif
  );
  font-size: var(--spoqa-title-40bold-font-size, 40px);
  line-height: var(--spoqa-title-40bold-line-height, 145%);
  letter-spacing: var(--spoqa-title-40bold-letter-spacing, -0.05em);
  font-weight: var(--spoqa-title-40bold-font-weight, 700);
}
.slider-container {
  width: 100%;
}
/* .slider-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 20px;
} */

.slider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 56px;
  align-items: center;
  justify-content: center;
}

.slide-card {
  flex-shrink: 0; /* 슬라이드 카드가 축소되지 않도록 설정 */
  width: 384px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
}
.card-top {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 4px 4px 12px 0px rgba(17, 24, 39, 0.1);
}
.card-top-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card-top-text {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.number {
  color: var(--Alias-Color-Text-White-Text-Tertiary, rgba(255, 255, 255, 0.64));

  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}
.card-top-title {
  color: var(--Alias-Color-Text-White-Text-Primary, #fff);

  /* Spoqa/Title/SubTitle/24_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
  letter-spacing: -1.2px;
}
.tag-box {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  font-family: "Spoqa Han Sans Neo";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 23.2px */
  letter-spacing: -0.8px;
}
.card-bottom {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  height: 152px;
  width: 100%;
}
.text-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex;
  gap: 8px;
}
.list-dot {
  width: 4px;
  height: 26px;
}
.card-bottom-title {
  color: var(--Alias-Color-Text-Dark-Text-Secondary, rgba(23, 30, 38, 0.78));

  /* Spoqa/Body/18_Medium */
  font-family: "Spoqa Han Sans Neo";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
  letter-spacing: -0.9px;
}
