.section-6,
.section-6 * {
  box-sizing: border-box;
}
.section06-wrap {
  width: 100%;
  background: #113056;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-6 {
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
}
.s6-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.s6-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.s6-title {
  color: #fff;
  text-align: center;

  /* Web/Title/S-24-Bold */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 34.8px */
}
.s6-text {
  color: #fff;

  /* Spoqa/Title/40_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 58px */
  letter-spacing: -2px;
}
.inquiry-btn {
  display: flex;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid #fff;
  width: fit-content;
  background: none;
  cursor: pointer;
}
.inquiry-btn-text {
  color: #fff;

  /* Spoqa/Title/SubTitle/20_Bold */
  font-family: "Spoqa Han Sans Neo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 29px */
  letter-spacing: -1px;
}
